@import url("./fontFamily.css");

html {
  min-height: 100%;
}

body {
  color: #888ea8;
  height: 100%;
  font-size: 0.875rem;
  background: #f1f2f3;
  overflow-x: hidden;
  overflow-y: auto;
  letter-spacing: 0.0312rem;
  font-family: "Hellix", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3b3f5c;
}

:focus {
  outline: none;
}

::-moz-selection {
  /* Code for Firefox */
  color: #4361ee;
  background: transparent;
}

::selection {
  color: #4361ee;
  background: transparent;
}

p {
  margin-top: 0;
  margin-bottom: 0.625rem;
  color: #515365;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #191e3a;
}

strong {
  font-weight: 600;
}

code {
  color: #e7515a;
}

select.form-custom::-ms-expand {
  display: none;
}

.custom-file-input:focus~.custom-file-label {
  border: 1px solid #3b3f5c;
  box-shadow: none;
}

.custom-file-input:focus~.custom-file-label::after {
  border: none;
  border-left: 1px solid #3b3f5c;
}

.lead a.btn.btn-primary.btn-lg {
  margin-top: 15px;
  border-radius: 4px;
}

.jumbotron {
  background-color: #1b2e4b;
}

.mark,
mark {
  background-color: #fff9ed;
}

.modal-content {
  background: #0e1726;
}

.code-section-container {
  margin-top: 20px;
  text-align: left;
}

.toggle-code-snippet {
  border: none;
  background-color: transparent !important;
  padding: 0px !important;
  box-shadow: none !important;
  color: #888ea8 !important;
  margin-bottom: -24px;
  border-bottom: 1px dashed #bfc9d4;
  border-radius: 0;
}

.toggle-code-snippet svg {
  color: #4361ee;
}

.code-section {
  padding: 0;
  height: 0;
}

.code-section-container.show-code .code-section {
  margin-top: 20px;
  height: auto;
}

.code-section pre {
  margin-bottom: 0;
  height: 0;
  padding: 0;
  border-radius: 6px;
}

.code-section-container.show-code .code-section pre {
  height: auto;
  padding: 22px;
}

.code-section code {
  color: #fff;
}

/*blockquote*/
blockquote.blockquote {
  color: #009688;
  padding: 20px 20px 20px 14px;
  font-size: 0.875rem;
  background-color: #060818;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #060818;
  border-left: 2px solid #009688;
}

blockquote.blockquote>p {
  margin-bottom: 0;
}

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

blockquote .small,
blockquote footer,
blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #888ea8;
}

blockquote.media-object.m-o-border-right {
  border-right: 4px solid #009688;
  border-left: none;
}

blockquote.media-object .media .usr-img img {
  width: 55px;
}

/* Icon List */

.list-icon {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.list-icon li:not(:last-child) {
  margin-bottom: 15px;
}

.list-icon svg {
  width: 18px;
  height: 18px;
  color: #4361ee;
  margin-right: 2px;
  vertical-align: sub;
}

.list-icon .list-text {
  font-size: 14px;
  font-weight: 600;
  color: #515365;
  letter-spacing: 1px;
}

a {
  color: #e0e6ed;
  outline: none;
}

a:hover {
  color: #bfc9d4;
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

button:focus,
textarea,
textarea:focus {
  outline: none;
}

.btn-link:hover {
  text-decoration: none;
}

span.blue {
  color: #4361ee;
}

span.green {
  color: #1abc9c;
}

span.red {
  color: #e7515a;
}

/*      CARD    */

.card {
  border: 1px solid #fff;
  border-radius: 26px;
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

/*      Form Group Label       */

.form-group label,
label {
  font-size: 15px;
  color: #e70765;
  letter-spacing: 1px;
}

/*  Disable forms     */

.custom-control-input:disabled~.custom-control-label {
  color: #d3d3d3;
  cursor: no-drop;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f3f6f9;
  cursor: no-drop;
  color: #000;
}

.custom-control-input:disabled~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::before {
  background-color: #1f2940;
  cursor: no-drop;
}

/* Form Control */

.form-control {
  height: auto;
  border: 1px solid lightgrey;
  color: #000;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  height: calc(1.4em + 1.4rem + -4px);
  /* padding: 0.75rem 1.25rem; */
  border-radius: 12px;
  background: #fff;
  display: flex;
  align-items: center;
}

.form-control::placeholder {
  color: #eaeaea;
  display: flex;
  align-items: center;
}

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */

  .form-control {
    color: #0e1726;
  }
}

.form-control[type="range"] {
  padding: 0;
}

.form-control:focus {
  box-shadow: none;
  border-color: #2196f3;
  color: #ea0062;

  font-weight: 500;
}

label:focus {
  color: #2196f3;
}

.form-control::-webkit-input-placeholder {
  color: #939393;
  font-size: 15px;
}

.form-control::-ms-input-placeholder {
  color: #939393;
  font-size: 15px;
}

.form-control::-moz-placeholder {
  color: #939393;
  font-size: 15px;
}

.form-control:focus::-webkit-input-placeholder {
  color: #d3d3d3;
  font-size: 15px;
}

.form-control:focus::-ms-input-placeholder {
  color: #d3d3d3;
  font-size: 15px;
}

.form-control:focus::-moz-placeholder {
  color: #d3d3d3;
  font-size: 15px;
}

.form-control.form-control-lg {
  font-size: 19px;
  padding: 11px 20px;
}

.form-control.form-control-sm {
  padding: 7px 16px;
  font-size: 13px;
}

/*      Custom Select       */

.custom-control-label::before {
  background: #1b2e4b;
  border-color: #1b2e4b;
}

/*      Custom Select       */

.custom-select {
  height: auto;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  border: 1px solid #1b2e4b;
  color: #009688;
  background: #1b2e4b;
  background: #1b2e4b url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23009688' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
}

.custom-select.custom-select-lg {
  font-size: 18px;
  padding: 16px 20px;
}

.custom-select.custom-select-sm {
  font-size: 13px;
  padding: 7px 16px;
}

.custom-select:focus {
  box-shadow: none;
  border-color: #3b3f5c;
  color: #25d5e4;
  background-color: #315283;
}

/*      Form Control File       */

.form-control-file {
  width: 100%;
  color: #805dca;
}

.form-control-file::-webkit-file-upload-button {
  letter-spacing: 1px;
  padding: 9px 20px;
  text-shadow: none;
  font-size: 12px;
  color: #fff;
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  transition: 0.2s ease-out;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #805dca;
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  border-radius: 4px;
  border: transparent;
  outline: none;
}

.form-control-file::-ms-file-upload-button {
  letter-spacing: 1px;
  padding: 9px 20px;
  text-shadow: none;
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  transition: 0.2s ease-out;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #805dca;
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  border-radius: 4px;
  border: transparent;
  outline: none;
}

.form-control-file.form-control-file-rounded::-webkit-file-upload-button {
  -webkit-border-radius: 1.875rem !important;
  -moz-border-radius: 1.875rem !important;
  -ms-border-radius: 1.875rem !important;
  -o-border-radius: 1.875rem !important;
  border-radius: 1.875rem !important;
}

select.form-control.form-custom {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  vertical-align: middle;
  background: #fff url(../img/arrow-down.png) no-repeat right 0.75rem center;
  background-size: 13px 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*      Form Control Custom File       */

.custom-file {
  height: auto;
}

.custom-file input {
  height: auto;
}

.custom-file-label {
  height: auto;
  border: 1px solid #1b2e4b;
  color: #009688;
  font-size: 15px;
  padding: 8px 10px;
  letter-spacing: 1px;
  background-color: #1b2e4b;
}

.custom-file-label::after {
  height: auto;
  padding: 8px 12px;
  color: #bfc9d4;
  background-color: #191e3a;
}

/*      Input Group      */

.input-group button:hover,
.input-group .btn:hover,
.input-group button:focus,
.input-group .btn:focus {
  transform: none;
}

.input-group .dropdown-menu {
  border: none;
  z-index: 1028;
  box-shadow: none;
  padding: 10px;
  padding: 0.35rem 0;
  top: 0 !important;
  right: auto;
  border-radius: 8px;
  background-color: #1b2e4b;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%),
    0 3px 5px -1px rgb(0 0 0 / 20%);
}

.input-group .dropdown-menu a.dropdown-item {
  border-radius: 5px;
  width: 100%;
  padding: 6px 17px;
  clear: both;
  font-weight: 500;
  color: #bfc9d4;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 13px;
}

.input-group .dropdown-menu a.dropdown-item:hover,
.input-group .dropdown-menu .dropdown-item:hover {
  color: #2196f3;
}

.input-group .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #0e1726;
}

/*      Input Group append       */

.input-group .input-group-prepend .input-group-text {
  border: 1px solid #1b2e4b;
  background-color: #191e3a;
  color: #888ea8;
}

.input-group .input-group-prepend .input-group-text svg {
  color: #888ea8;
}

.input-group:hover .input-group-prepend .input-group-text svg {
  color: #4361ee;
  fill: rgba(27, 85, 226, 0.23921568627450981);
}

/*      Input Group Append       */
.input-group .input-group-append .input-group-text {
  border: 1px solid #1b2e4b;
  background-color: #191e3a;
  color: #888ea8;
}

.input-group .input-group-append .input-group-text svg {
  color: #888ea8;
}

.input-group:hover .input-group-append .input-group-text svg {
  color: #4361ee;
  fill: rgba(27, 85, 226, 0.23921568627450981);
}

/*      Validation Customization      */

.invalid-feedback {
  color: #e7515a;
  font-size: 13px;
  letter-spacing: 1px;
}

.valid-feedback {
  color: #009688;
  font-size: 13px;
  letter-spacing: 1px;
}

.valid-tooltip {
  background-color: #009688;
}

.invalid-tooltip {
  background-color: #e7515a;
}

.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #009688;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23009688' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
  color: #009688;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e7515a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
  color: #e7515a;
}

.dropdown-toggle:after {
  display: none;
}

.dropup .dropdown-toggle::after {
  display: none;
}

.dropright .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: none;
}

.dropdown-toggle svg.feather[class*="feather-chevron-"] {
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

.btn {
  padding: 0.4375rem 1.25rem;
  text-shadow: none;
  font-size: 14px;
  color: #3b3f5c;
  font-weight: normal;
  white-space: normal;
  word-wrap: break-word;
  transition: 0.2s ease-out;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #f1f2f3;
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.btn svg {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
}

.btn.rounded-circle {
  height: 40px;
  width: 40px;
  padding: 8px 8px;
}

.btn:hover,
.btn:focus {
  color: #3b3f5c;
  /* background-color: #d9386a;
    border-color: #d9386a; */
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  /* -webkit-transform: translateY(-3px);
      transform: translateY(-3px); */
}

.btn-group .btn:hover,
.btn-group .btn:focus {
  -webkit-transform: none;
  transform: none;
}

.btn.disabled,
.btn.btn[disabled] {
  background-color: #f1f2f3;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.13);
}

.btn.disabled:hover,
.btn.btn[disabled]:hover {
  cursor: not-allowed;
}

.btn .caret {
  border-top-color: #0e1726;
  margin-top: 0;
  margin-left: 3px;
  vertical-align: middle;
}

.btn+.caret,
.btn+.dropdown-toggle .caret {
  margin-left: 0;
}

.btn-group>.btn,
.btn-group .btn {
  padding: 8px 14px;
}

.btn-group-lg>.btn,
.btn-group-lg .btn {
  font-size: 1.125rem;
}

.btn-group-lg>.btn,
.btn-lg,
.btn-group>.btn.btn-lg,
.btn-group .btn.btn-lg {
  padding: 0.625rem 1.5rem;
  font-size: 16px;
}

.btn-group-lg>.btn,
.btn-group-lg .btn {
  font-size: 1.125rem;
}

.btn-group-sm>.btn,
.btn-sm,
.btn-group>.btn.btn-sm,
.btn-group .btn.btn-sm {
  font-size: 0.6875rem;
}

.btn-group .dropdown-menu {
  border: none;
  z-index: 1028;
  box-shadow: none;
  padding: 10px;
  padding: 0.35rem 0;
  top: 0 !important;
  right: auto;
  border-radius: 8px;
  background-color: #1b2e4b;
}

.btn-group .dropdown-menu a.dropdown-item {
  border-radius: 5px;
  width: 100%;
  padding: 6px 17px;
  clear: both;
  font-weight: 500;
  color: #bfc9d4;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 13px;
}

.dropdown-divider {
  border-top: 1px solid #0e1726;
}

.btn-group .dropdown-menu a.dropdown-item:hover {
  color: #2196f3;
}

.btn-group .dropdown-menu a.dropdown-item svg {
  cursor: pointer;
  color: #888ea8;
  margin-right: 6px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  fill: rgba(0, 23, 55, 0.08);
}

.btn-group .dropdown-menu a.dropdown-item:hover svg {
  color: #4361ee;
}

.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu {
  border: none;
  z-index: 899;
  box-shadow: none;
  padding: 10px;
  padding: 0.35rem 0;
  transition: top 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s,
    visibility 0.3s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
  display: block !important;
  transform: none !important;
  top: 0 !important;
  right: 0;
  left: auto !important;
  border-radius: 8px;
  background: #1b2e4b;
  box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%),
    0 3px 5px -1px rgb(0 0 0 / 20%);
}

.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.right {
  right: auto;
  left: auto !important;
}

.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.left {
  right: 0;
  left: auto !important;
}

.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  top: 21px !important;
}

.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item {
  border-radius: 5px;
  display: block;
  width: 100%;
  padding: 6px 17px;
  clear: both;
  font-weight: 500;
  color: #bfc9d4;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 13px;
}

.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item svg {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  vertical-align: bottom;
  color: #888ea8;
}

.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item:hover svg {
  color: #2196f3;
}

.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item.active,
.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item:active {
  background-color: transparent;
  color: #25d5e4;
  font-weight: 700;
}

.dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu a.dropdown-item:hover {
  color: #2196f3;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn.focus,
.btn:focus,
.btn-success:focus,
.btn-info:focus,
.btn-danger:focus,
.btn-warning:focus,
.btn-secondary:focus,
.btn-dark:focus,
.btn-outline-success:focus,
.btn-outline-info:focus,
.btn-outline-danger:focus,
.btn-outline-warning:focus,
.btn-outline-secondary:focus,
.btn-outline-dark:focus .btn-light-default:focus,
.btn-light-primary:focus,
.btn-light-success:focus,
.btn-light-info:focus,
.btn-light-danger:focus,
.btn-light-warning:focus,
.btn-light-secondary:focus,
.btn-light-dark:focus {
  box-shadow: none;
}

/*      Default Buttons       */

.btn-primary {
  color: #fff !important;
  background-color: #4361ee !important;
  border-color: #4361ee;

}

.btn-primary:hover,
.btn-primary:focus {
  color: #fff !important;
  background-color: #4361ee;
  box-shadow: none;
  border-color: #4361ee;
}

.btn-primary:active,
.btn-primary.active {
  background-color: #4361ee;
  border-top: 1px solid #4361ee;
}

.btn-primary.disabled,
.btn-primary.btn[disabled],
.btn-primary:disabled {
  background-color: #4361ee;
  border-color: #4361ee;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
  color: #fff !important;
  background-color: #2aebcb;
  border-color: #2aebcb;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #4361ee;
  border-color: #4361ee;
}

.btn-primary .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-primary.dropdown-toggle {
  background-color: #eaf1ff;
}

.btn-secondary {
  color: #fff !important;
  background-color: #805dca;
  border-color: #805dca;
  box-shadow: 0 10px 20px -10px rgba(92, 26, 195, 0.59);
}

.btn-secondary:hover,
.btn-secondary:focus {
  color: #fff !important;
  background-color: #805dca;
  box-shadow: none;
  border-color: #805dca;
}

.btn-secondary:active,
.btn-secondary.active {
  background-color: #805dca;
  border-top: 1px solid #805dca;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: #805dca;
  border-color: #805dca;
}

.btn-secondary.disabled,
.btn-secondary.btn[disabled],
.btn-secondary:disabled {
  background-color: #805dca;
  border-color: #805dca;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-secondary .caret {
  border-top-color: #fff;
}

.btn-info {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
  box-shadow: 0 10px 20px -10px rgba(33, 150, 243, 0.59);
}

.btn-info:hover,
.btn-info:focus {
  color: #fff !important;
  background-color: #2196f3;
  box-shadow: none;
  border-color: #2196f3;
}

.btn-info:active,
.btn-info.active {
  background-color: #2196f3;
  border-top: 1px solid #2196f3;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-info.disabled,
.btn-info.btn[disabled],
.btn-info:disabled {
  background-color: #2196f3;
  border-color: #2196f3;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
  color: #fff !important;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-info .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-info.dropdown-toggle {
  background-color: #e7f7ff;
}

.btn-warning {
  color: #fff !important;
  background-color: #e2a03f;
  border-color: #e2a03f;
  box-shadow: 0 10px 20px -10px rgba(226, 160, 63, 0.59);
}

.btn-warning:hover,
.btn-warning:focus {
  color: #fff !important;
  background-color: #e2a03f;
  box-shadow: none;
  border-color: #e2a03f;
}

.btn-warning:active,
.btn-warning.active {
  background-color: #e2a03f;
  border-top: 1px solid #e2a03f;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
  color: #0e1726;
  background-color: #e2a03f;
  border-color: #e2a03f;
}

.btn-warning.disabled,
.btn-warning.btn[disabled],
.btn-warning:disabled {
  background-color: #e2a03f;
  border-color: #e2a03f;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
  color: #fff !important;
  background-color: #e2a03f;
  border-color: #e2a03f;
}

.btn-warning .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-warning.dropdown-toggle {
  background-color: #df8505;
}

.btn-danger {
  color: #fff !important;
  background-color: #e7515a;
  border-color: #e7515a;
  box-shadow: 0 10px 20px -10px rgba(231, 81, 90, 0.59);
}

.btn-danger:hover,
.btn-danger:focus {
  color: #fff !important;
  background-color: #e7515a;
  box-shadow: none;
  border-color: #e7515a;
}

.btn-danger:active,
.btn-danger.active {
  background-color: #e7515a;
  border-top: 1px solid #e7515a;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  color: #fff !important;
  background-color: #e7515a;
  border-color: #e7515a;
}

.btn-danger.disabled,
.btn-danger.btn[disabled],
.btn-danger:disabled {
  background-color: #e7515a;
  border-color: #e7515a;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
  color: #fff !important;
  background-color: #c00;
  border-color: #c00;
}

.btn-danger .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-danger.dropdown-toggle {
  background-color: #a9302a;
}

.btn-dark {
  color: #fff !important;
  background-color: #131628;
  border-color: #131628;
  box-shadow: 0 10px 20px -10px rgba(44, 47, 71, 0.59);
}

.btn-dark:hover,
.btn-dark:focus {
  color: #fff !important;
  background-color: #262941;
  box-shadow: none;
  border-color: #262941;
}

.btn-dark:active,
.btn-dark.active {
  background-color: #131628;
  border-top: 1px solid #131628;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
  color: #fff !important;
  background-color: #3b3f5c;
  border-color: #3b3f5c;
}

.btn-dark.disabled,
.btn-dark.btn[disabled],
.btn-dark:disabled {
  background-color: #3b3f5c;
  border-color: #3b3f5c;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-dark .caret {
  border-top-color: #fff;
}

.btn-group.open .btn-dark.dropdown-toggle {
  background-color: #484848;
}

.btn-success {
  color: #fff !important;
  background-color: #1abc9c;
  border-color: #1abc9c;
  box-shadow: 0 10px 20px -10px rgba(141, 191, 66, 0.59);
}

.btn-success:hover,
.btn-success:focus {
  color: #fff !important;
  background-color: #1abc9c;
  box-shadow: none;
  border-color: #1abc9c;
}

.btn-success:active,
.btn-success.active {
  background-color: #1abc9c;
  border-top: 1px solid #1abc9c;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff !important;
  background-color: #1abc9c;
  border-color: #1abc9c;
}

.btn-success.disabled,
.btn-success.btn[disabled],
.btn-success:disabled {
  background-color: #1abc9c;
  border-color: #1abc9c;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
  color: #fff !important;
  background-color: #17c678;
  border-color: #17c678;
}

.btn-success .caret {
  border-top-color: #fff;
}

/*-----/Button Light Colors------*/

.btn.box-shadow-none {
  border: none;
}

.btn.box-shadow-none:hover,
.btn.box-shadow-none:focus {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.box-shadow-none {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.box-shadow-none:not(:disabled):not(.disabled).active,
.btn.box-shadow-none:not(:disabled):not(.disabled):active,
.show>.btn.box-shadow-none.dropdown-toggle {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.btn-group.open .btn-success.dropdown-toggle {
  background-color: #499249;
}

.btn-dismiss {
  color: #0e1726;
  background-color: #fff !important;
  border-color: #fff;
  padding: 3px 7px;
}

.btn-dismiss:hover,
.btn-dismiss:focus {
  color: #0e1726;
  background-color: #fff;
}

.btn-dismiss:active,
.btn-dismiss.active {
  background-color: #fff;
  border-top: 1px solid #fff;
}

.btn-group>.btn i {
  margin-right: 3px;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16),
    0 0px 0px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16),
    0 0px 0px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
}

.btn-group>.btn+.dropdown-toggle {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/*
      Btn group dropdown-toggle
  */
.btn-group>.btn+.dropdown-toggle.btn-primary {
  border-left: 1px solid rgb(68, 104, 253);
}

.btn-group>.btn+.dropdown-toggle.btn-success {
  border-left: 1px solid rgb(163, 198, 111);
}

.btn-group>.btn+.dropdown-toggle.btn-info {
  border-left: 1px solid rgb(73, 172, 251);
}

.btn-group>.btn+.dropdown-toggle.btn-warning {
  border-left: 1px solid rgb(245, 180, 85);
}

.btn-group>.btn+.dropdown-toggle.btn-danger {
  border-left: 1px solid rgb(241, 132, 139);
}

.btn-group>.btn+.dropdown-toggle.btn-dark {
  border-left: 1px solid rgb(112, 118, 122);
}

.btn-group>.btn+.dropdown-toggle.btn-secondary {
  border-left: 1px solid rgb(131, 83, 220);
}

.btn-group.dropleft .dropdown-toggle-split {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group.dropleft .btn-primary:not(.dropdown-toggle-split) {
  border-left: 1px solid rgb(68, 104, 253);
}

.btn-group.dropleft .btn-success:not(.dropdown-toggle-split) {
  border-left: 1px solid rgb(163, 198, 111);
}

.btn-group.dropleft .btn-info:not(.dropdown-toggle-split) {
  border-left: 1px solid rgb(73, 172, 251);
}

.btn-group.dropleft .btn-warning:not(.dropdown-toggle-split) {
  border-left: 1px solid rgb(245, 180, 85);
}

.btn-group.dropleft .btn-danger:not(.dropdown-toggle-split) {
  border-left: 1px solid rgb(241, 132, 139);
}

.btn-group.dropleft .btn-dark:not(.dropdown-toggle-split) {
  border-left: 1px solid rgb(112, 118, 122);
}

.btn-group.dropleft .btn-secondary:not(.dropdown-toggle-split) {
  border-left: 1px solid rgb(131, 83, 220);
}

.btn .badge.badge-align-right {
  position: absolute;
  top: -1px;
  right: 8px;
}

.dropup .btn .caret {
  border-bottom-color: #0e1726;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: #4361ee;
  color: #fff !important;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
  background-color: #1abc9c;
  color: #fff !important;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
  background-color: #2196f3;
  color: #fff !important;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
  background-color: #e7515a;
  color: #fff !important;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  background-color: #e2a03f;
  color: #fff !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  background-color: #805dca;
  color: #fff !important;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
  background-color: #3b3f5c;
  color: #fff !important;
}

.show>.btn-outline-primary.dropdown-toggle:after,
.show>.btn-outline-success.dropdown-toggle:after,
.show>.btn-outline-info.dropdown-toggle:after,
.show>.btn-outline-danger.dropdown-toggle:after,
.show>.btn-outline-warning.dropdown-toggle:after,
.show>.btn-outline-secondary.dropdown-toggle:after,
.show>.btn-outline-dark.dropdown-toggle:after {
  color: #fff !important;
}

.show>.btn-outline-primary.dropdown-toggle:before,
.show>.btn-outline-success.dropdown-toggle:before,
.show>.btn-outline-info.dropdown-toggle:before,
.show>.btn-outline-danger.dropdown-toggle:before,
.show>.btn-outline-warning.dropdown-toggle:before,
.show>.btn-outline-secondary.dropdown-toggle:before,
.show>.btn-outline-dark.dropdown-toggle:before {
  color: #fff !important;
}

.btn-outline-primary {
  border: 1px solid #4361ee !important;
  color: #4361ee !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-info {
  border: 1px solid #2196f3 !important;
  color: #2196f3 !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-warning {
  border: 1px solid #e2a03f !important;
  color: #e2a03f !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-success {
  border: 1px solid #1abc9c !important;
  color: #1abc9c !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-danger {
  border: 1px solid #e7515a !important;
  color: #e7515a !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-secondary {
  border: 1px solid #805dca !important;
  color: #805dca !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-dark {
  border: 1px solid #3b3f5c !important;
  color: #3b3f5c !important;
  background-color: transparent;
  box-shadow: none;
}

.btn-outline-primary:hover,
.btn-outline-info:hover,
.btn-outline-warning:hover,
.btn-outline-success:hover,
.btn-outline-danger:hover,
.btn-outline-secondary:hover,
.btn-outline-dark:hover {
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #4361ee;
  box-shadow: 0 10px 20px -10px rgba(27, 85, 226, 0.59);
}

.btn-outline-info:hover {
  color: #fff !important;
  background-color: #2196f3;
  box-shadow: 0 10px 20px -10px rgba(33, 150, 243, 0.588);
}

.btn-outline-warning:hover {
  color: #fff !important;
  background-color: #e2a03f;
  box-shadow: 0 10px 20px -10px rgba(226, 160, 63, 0.588);
}

.btn-outline-success:hover {
  color: #fff !important;
  background-color: #1abc9c;
  box-shadow: 0 10px 20px -10px rgba(141, 191, 66, 0.59);
}

.btn-outline-danger:hover {
  color: #fff !important;
  background-color: #e7515a;
  box-shadow: 0 10px 20px -10px rgba(231, 81, 90, 0.588);
}

.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: #805dca;
  box-shadow: 0 10px 20px -10px rgba(92, 26, 195, 0.59);
}

.btn-outline-dark:hover {
  color: #fff !important;
  background-color: #3b3f5c;
  box-shadow: 0 10px 20px -10px rgba(59, 63, 92, 0.59);
}

/*      Dropdown Toggle       */

.btn-rounded {
  -webkit-border-radius: 1.875rem !important;
  -moz-border-radius: 1.875rem !important;
  -ms-border-radius: 1.875rem !important;
  -o-border-radius: 1.875rem !important;
  border-radius: 1.875rem !important;
}

/*
      ===========================
          Data Marker ( dot )
      ===========================
  */
.data-marker {
  padding: 2px;
  border-radius: 50%;
  font-size: 18px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.data-marker-success {
  background-color: #1abc9c;
}

.data-marker-warning {
  background-color: #e2a03f;
}

.data-marker-danger {
  background-color: #e7515a;
}

.data-marker-info {
  background-color: #e7515a;
}

.data-marker-dark {
  background-color: #e7515a;
}

.badge {
  font-weight: 600;
  line-height: 1.4;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 600;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
}

.badge:hover {
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.badge.badge-enabled {
  background-color: #1abc9c;
  color: #fff;
}

.badge.badge-disable {
  background-color: #e7515a;
  color: #fff;
}

.badge-pills {
  border-radius: 30px;
}

.badge-classic {
  border-radius: 0;
}

.badge-collapsed-img img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #515365;
  margin-left: -21px;
}

.badge-collapsed-img.badge-tooltip img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.3);
  margin-left: -21px;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.badge-collapsed-img.badge-tooltip img:hover {
  -webkit-transform: translateY(-5px) scale(1.02);
  transform: translateY(-5px) scale(1.02);
}

.badge-collapsed-img.translateY-axis img {
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.badge-collapsed-img.translateY-axis img:hover {
  -webkit-transform: translateY(-5px) scale(1.02);
  transform: translateY(-5px) scale(1.02);
}

.badge-collapsed-img.rectangle-collapsed img {
  width: 45px;
  height: 32px;
}

.badge-collapsed-img.translateX-axis img {
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.badge-collapsed-img.translateX-axis img:hover {
  -webkit-transform: translateX(5px) scale(1.02);
  transform: translateX(5px) scale(1.02);
}

.badge-primary {
  color: #fff;
  background-color: #4361ee;
}

.badge-info {
  color: #fff;
  background-color: #2196f3;
}

.badge-success {
  color: #fff;
  background-color: #1abc9c;
}

.badge-danger {
  color: #fff;
  background-color: #cf1c59;
}

.badge-warning {
  color: #fff;
  background-color: #e2a03f;
}

.badge-dark {
  color: #fff;
  background-color: #3b3f5c;
}

.badge-secondary {
  background-color: #805dca;
}

.outline-badge-primary {
  color: #4361ee;
  background-color: transparent;
  border: 1px solid #4361ee;
}

.outline-badge-info {
  color: #2196f3;
  background-color: transparent;
  border: 1px solid #2196f3;
}

.outline-badge-success {
  color: #1abc9c;
  background-color: transparent;
  border: 1px solid #1abc9c;
}

.outline-badge-danger {
  color: #e7515a;
  background-color: transparent;
  border: 1px solid #e7515a;
}

.outline-badge-warning {
  color: #e2a03f;
  background-color: transparent;
  border: 1px solid #e2a03f;
}

.outline-badge-dark {
  color: #3b3f5c;
  background-color: transparent;
  border: 1px solid #3b3f5c;
}

.outline-badge-secondary {
  color: #805dca;
  background-color: transparent;
  border: 1px solid #805dca;
}

.outline-badge-primary:focus,
.outline-badge-primary:hover {
  background-color: #4361ee;
  color: #eaf1ff;
}

.outline-badge-secondary:focus,
.outline-badge-secondary:hover {
  color: #f3effc;
  background-color: #805dca;
}

.outline-badge-success:focus,
.outline-badge-success:hover {
  color: #ddf5f0;
  background-color: #1abc9c;
}

.outline-badge-danger:focus,
.outline-badge-danger:hover {
  color: #fff5f5;
  background-color: #e7515a;
}

.outline-badge-warning:focus,
.outline-badge-warning:hover {
  color: #fff9ed;
  background-color: #e2a03f;
}

.outline-badge-info:focus,
.outline-badge-info:hover {
  color: #e7f7ff;
  background-color: #2196f3;
}

.outline-badge-dark:focus,
.outline-badge-dark:hover {
  color: #e3e4eb;
  background-color: #3b3f5c;
}

/*      Link     */

.badge[class*="link-badge-"] {
  cursor: pointer;
}

.link-badge-primary {
  color: #4361ee;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-info {
  color: #2196f3;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-success {
  color: #1abc9c;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-danger {
  color: #e7515a;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-warning {
  color: #e2a03f;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-dark {
  color: #3b3f5c;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-secondary {
  color: #805dca;
  background-color: transparent;
  border: 1px solid transparent;
}

.link-badge-primary:focus,
.link-badge-primary:hover {
  color: #4361ee;
  background-color: transparent;
}

.link-badge-secondary:focus,
.link-badge-secondary:hover {
  color: #6f51ea;
  background-color: transparent;
}

.link-badge-success:focus,
.link-badge-success:hover {
  color: #2ea37d;
  background-color: transparent;
}

.link-badge-danger:focus,
.link-badge-danger:hover {
  color: #e7515a;
  background-color: transparent;
}

.link-badge-warning:focus,
.link-badge-warning:hover {
  color: #dea82a;
  background-color: transparent;
}

.link-badge-info:focus,
.link-badge-info:hover {
  color: #009eda;
  background-color: transparent;
}

.link-badge-dark:focus,
.link-badge-dark:hover {
  color: #454656;
  background-color: transparent;
}

/* Custom Dropdown*/
.custom-dropdown .dropdown-toggle::after,
.custom-dropdown-icon .dropdown-toggle::after {
  display: none;
}

.custom-dropdown .dropdown-toggle::before,
.custom-dropdown-icon .dropdown-toggle::before {
  display: none;
}

.custom-dropdown .dropdown-menu,
.custom-dropdown-icon .dropdown-menu {
  min-width: 11rem;
  border-radius: 4px;
  border: none;
  border: 1px solid #e0e6ed;
  z-index: 899;
  box-shadow: none;
  top: 15px !important;
  padding: 10px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  background: #1b2e4b;
}

.custom-dropdown .dropdown-item.active,
.custom-dropdown .dropdown-item:active,
.custom-dropdown .dropdown-item:hover,
.custom-dropdown-icon .dropdown-item.active,
.custom-dropdown-icon .dropdown-item:active,
.custom-dropdown-icon .dropdown-item:hover {
  color: #888ea8;
  background-color: #0e1726;
}

.custom-dropdown .dropdown-item,
.custom-dropdown-icon .dropdown-item {
  font-size: 13px;
  color: #888ea8;
  display: block;
  font-weight: 700;
  padding: 11px 8px;
  font-size: 12px;
  border-radius: 4px;
}

.custom-dropdown-icon .dropdown-menu .dropdown-item svg {
  width: 20px;
  height: 20px;
  margin-right: 3px;
  color: #888ea8;
}

.custom-dropdown .dropdown-item.active svg,
.custom-dropdown .dropdown-item:active svg,
.custom-dropdown .dropdown-item:hover svg,
.custom-dropdown-icon .dropdown-item.active svg,
.custom-dropdown-icon .dropdown-item:active svg,
.custom-dropdown-icon .dropdown-item:hover svg {
  color: #4361ee;
}

.status.rounded-tooltip .tooltip-inner {
  border-radius: 20px;
  padding: 8px 20px;
}

.tooltip-inner {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.popover {
  z-index: 999;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-bottom-color: #b3b3b3;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
  background-color: #f3f6f9 !important;
  color: #000;
}

.help-block,
.help-inline {
  color: #555555;
}

.controls {
  position: relative;
}

.search-form-control {
  border-radius: 0.25rem;
}

/*  Table   */

.table-bordered {
  border: 1px solid #f1f2f3;
}

.table-striped tbody tr:nth-of-type(odd) {
  /* background-color: #3c333d !important; */
}

.table>tbody>tr>td {
  vertical-align: middle;
  color: #f2f5ff;
  font-size: 17px;
  font-weight: 600;
}

.table>thead>tr>th {
  color: #f2f5ff;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.table>tbody>tr>td .usr-img-frame {
  background-color: #bfc9d4;
  padding: 2px;
  width: 35px;
  height: 35px;
}

.table>tbody>tr>td .usr-img-frame img {
  width: 35px;
  margin: 0;
}

.table>tbody>tr>td .admin-name {
  font-weight: 700;
  color: #515365;
}

.table>tbody>tr>td .progress {
  width: 135px;
  height: 6px;
  margin: auto 0;
}

.table>tbody>tr>td svg.icon {
  width: 21px;
}

.table>tbody>tr>td .t-dot {
  background-color: #1f2940;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
}

.table>tbody>tr>td svg.t-icon {
  padding: 5px;
  border-radius: 50%;
  font-size: 11px;
  vertical-align: sub;
  cursor: pointer;
}

.table>tbody>tr>td svg.t-icon.t-hover-icon:hover {
  background-color: #e7515a;
  color: #fff;
}

.table-bordered td {
  border: 1px solid #ebedf2;
}

.table-bordered th {
  border: 1px solid #ebedf2;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none;
}

.table-hover:not(.table-light) tbody tr:hover {
  background-color: #1f2940;
}

.table-controls>li>a svg {
  color: #25d5e4;
}

.table tr td .custom-dropdown.t-custom-dropdown a.dropdown-toggle,
.table tr td .custom-dropdown-icon.t-custom-dropdown a.dropdown-toggle {
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.table-controls>li>a svg {
  color: #888ea8;
  width: 21px;
}

/*  Table   */

.table>thead>tr>th {
  color: #fff;
  font-size: 14px;
}

.table>tbody>tr>td {
  color: #101010 !important;
}

.table {
  background-color: #fff;
}

.table.table-hover tbody tr {
  background-color: #0c1c41;
}

#report.table.table-hover tbody tr {
  background-color: #e6d6fe;
}

.table td,
.table th,
.table thead th {
  border-color: #ffffff !important;
}

.table.table-hover tbody tr:hover {
  background-color: rgba(25, 30, 58, 0.6313725490196078);
}

.table.table>tbody>tr>td i.t-icon {
  padding: 5px;
  border-radius: 50%;
  font-size: 14px;
  vertical-align: sub;
  cursor: pointer;
  color: #1f2940 !important;
}

/*  Table Light  */

.table.table-light>thead>tr>th {
  color: #d3d3d3;
}

.table.table-light>tbody>tr>td {
  color: #ffffff;
}

.table-light {
  background-color: #3b3f5c !important;
}

.table-light.table-hover tbody tr {
  background-color: #3b3f5c;
}

.table-striped thead tr {
  background-color: #e70765;
  color: #ffffff !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f7f7f7;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: rgba(255, 106, 168, 0.08);
}

.table-light td,
.table-light th,
.table-light thead th {
  border-radius: 30px 30px 0px 0px !important;
}

.table-light.table-hover tbody tr:hover {
  background-color: rgba(25, 30, 58, 0.6313725490196078);
}

.table.table-light>tbody>tr>td i.t-icon {
  padding: 5px;
  border-radius: 50%;
  font-size: 14px;
  vertical-align: sub;
  cursor: pointer;
  color: #0e1726 !important;
}

table .badge-success,
table .badge-primary,
table .badge-warning,
table .badge-danger,
table .badge-info,
table .badge-secondary,
table .badge-dark {
  box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.2);
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  font-size: 15px;
}

.table>tfoot>tr>th {
  color: #e0e6ed;
}

.table-vertical-align tr,
.table-vertical-align th,
.table-vertical-align td {
  vertical-align: middle !important;
}

.statbox .widget-content:before,
.statbox .widget-content:after {
  display: table;
  content: "";
  line-height: 0;
  clear: both;
}

.nav-tabs>li>a {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.btn-toolbar {
  margin-left: 0px;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .input-group>.form-control {
    flex: 1 1 auto;
    width: 1%;
  }
}

.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.toast-primary {
  background: #4361ee;
}

.toast-header {
  background: #4361ee;
  color: #fff;
  border-bottom: 1px solid rgba(33, 150, 243, 0.3411764705882353);
}

.toast-header .meta-time {
  color: #f1f2f3;
}

.toast-header .close {
  color: #f1f2f3;
  opacity: 1;
  text-shadow: none;
}

.toast-body {
  padding: 16px 12px;
  color: #fff;
}

/*  
      ==========================
          Background Colors  
      ==========================
  */

/*  
      Default  
  */
.bg-primary {
  background-color: #4361ee !important;
  border-color: #4361ee;
  color: #fff;
}

.bg-success {
  background-color: #1abc9c !important;
  border-color: #1abc9c;
  color: #fff;
}

.bg-info {
  background-color: #2196f3 !important;
  border-color: #2196f3;
  color: #fff;
}

.bg-warning {
  background-color: #e2a03f !important;
  border-color: #e2a03f;
  color: #fff;
}

.bg-danger {
  background-color: #e7515a !important;
  border-color: #e7515a;
  color: #fff;
}

.bg-secondary {
  background-color: #805dca !important;
  border-color: #805dca;
  color: #fff;
}

.bg-dark {
  background-color: #fff;
  border-color: #3b3f5c;
  color: #fff;
}

/*  
      Light Background  
  */
.bg-light-primary {
  background-color: #eaf1ff !important;
  border-color: #eaf1ff;
  color: #2196f3;
}

.bg-light-success {
  background-color: #ddf5f0 !important;
  border-color: #ddf5f0;
  color: #1abc9c;
}

.bg-light-info {
  background-color: #e7f7ff !important;
  border-color: #e7f7ff;
  color: #2196f3;
}

.bg-light-warning {
  background-color: #fff9ed !important;
  border-color: #fff9ed;
  color: #e2a03f;
}

.bg-light-danger {
  background-color: #fff5f5 !important;
  border-color: #fff5f5;
  color: #e7515a;
}

.bg-light-secondary {
  background-color: #f3effc !important;
  border-color: #f3effc;
  color: #805dca;
}

.bg-light-dark {
  background-color: #e3e4eb;
  border-color: #e3e4eb;
  color: #fff;
}

/*  
      Progress Bar
  */

.progress {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: #191e3a;
  margin-bottom: 1.25rem;
  height: 16px;
  box-shadow: none;
}

.progress.progress-bar-stack .progress-bar:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.progress .progress-bar {
  font-size: 10px;
  font-weight: 700;
  box-shadow: 0 2px 4px rgba(0, 69, 255, 0.15), 0 8px 16px rgba(0, 69, 255, 0.2);
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 100;
}

.progress:not(.progress-bar-stack) .progress-bar {
  border-radius: 16px;
}

.progress-sm {
  height: 4px;
}

.progress-md {
  height: 10px;
}

.progress-lg {
  height: 20px;
}

.progress-xl {
  height: 25px;
}

.progress-striped .progress-bar {
  background-image: -webkit-gradient(linear,
      0 100%,
      100% 0,
      color-stop(0.25, rgba(255, 255, 255, 0.15)),
      color-stop(0.25, transparent),
      color-stop(0.5, transparent),
      color-stop(0.5, rgba(255, 255, 255, 0.15)),
      color-stop(0.75, rgba(255, 255, 255, 0.15)),
      color-stop(0.75, transparent),
      to(transparent));
  background-image: -webkit-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: -moz-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: -o-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
}

.progress .progress-title {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.progress .progress-title span {
  align-self: center;
}

.progress .progress-bar.bg-gradient-primary {
  background-color: #4361ee;
  background: linear-gradient(to right, #0081ff 0%, #0045ff 100%);
}

.progress .progress-bar.bg-gradient-info {
  background-color: #4361ee;
  background-image: linear-gradient(to right, #04befe 0%, #4481eb 100%);
}

.progress .progress-bar.bg-gradient-success {
  background-color: #4361ee;
  background-image: linear-gradient(to right, #3cba92 0%, #0ba360 100%);
}

.progress .progress-bar.bg-gradient-warning {
  background-color: #4361ee;
  background-image: linear-gradient(to right, #f09819 0%, #ff5858 100%);
}

.progress .progress-bar.bg-gradient-secondary {
  background-color: #4361ee;
  background-image: linear-gradient(to right, #7579ff 0%, #b224ef 100%);
}

.progress .progress-bar.bg-gradient-danger {
  background-color: #4361ee;
  background-image: linear-gradient(to right, #d09693 0%, #c71d6f 100%);
}

.progress .progress-bar.bg-gradient-dark {
  background-color: #4361ee;
  background-image: linear-gradient(to right, #2b5876 0%, #4e4376 100%);
}

.br-0 {
  border-radius: 0 !important;
}

.br-4 {
  border-radius: 4px !important;
}

.br-6 {
  border-radius: 6px !important;
}

.br-30 {
  border-radius: 30px !important;
}

.br-50 {
  border-radius: 50px !important;
}

.br-left-30 {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.br-right-30 {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.bx-top-6 {
  border-top-right-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.bx-bottom-6 {
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

/*      Badge Custom      */
.badge.counter {
  position: absolute;
  z-index: 2;
  right: 0;
  top: -10px;
  font-weight: 600;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  padding: 2px 0px;
  font-size: 12px;
}

.badge-chip {
  display: inline-block;
  padding: 0 25px;
  font-size: 16px;
  line-height: 42px;
  border-radius: 25px;
}

.badge-chip img {
  float: left;
  margin: 0px 10px 0px -26px;
  height: 44px;
  width: 44px;
  border-radius: 50%;
}

.badge-chip .closebtn {
  color: #f1f2f3;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.badge-chip .closebtn:hover {
  color: #fff;
}

/*-------text-colors------*/

.text-primary {
  color: #4361ee !important;
}

.text-success {
  color: #1abc9c !important;
}

.text-info {
  color: #2196f3 !important;
}

.text-danger {
  color: #e7515a !important;
}

.text-warning {
  color: #e2a03f !important;
}

.text-secondary {
  color: #805dca !important;
}

.text-dark {
  color: #101010 !important;
}

.text-muted {
  color: #888ea8 !important;
}

.text-white {
  color: #fff !important;
}

.text-black {
  color: #000 !important;
}

/*-----border main------*/
.border {
  border: 1px solid #e70765 !important;
}

.border-bottom {
  border-bottom: 1px solid !important;
}

.border-top {
  border-top: 1px solid !important;
}

.border-right {
  border-right: 1px solid !important;
}

.border-left {
  border-left: 1px solid !important;
}

.border-primary {
  border-color: #4361ee !important;
}

.border-info {
  border-color: #2196f3 !important;
}

.border-warning {
  border-color: #e2a03f !important;
}

.border-success {
  border-color: #1abc9c !important;
}

.border-danger {
  border-color: #e7515a !important;
}

.border-secondary {
  border-color: #805dca !important;
}

.border-dark {
  border-color: #3b3f5c !important;
}

/*-----border style------*/
.border-dotted {
  border-style: dotted !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-solid {
  border-style: solid !important;
}

.border-double {
  border-style: double !important;
}

/*-----border width------*/
.border-width-1px {
  border-width: 1px !important;
}

.border-width-2px {
  border-width: 2px !important;
}

.border-width-3px {
  border-width: 3px !important;
}

.border-width-4px {
  border-width: 4px !important;
}

.border-width-5px {
  border-width: 5px !important;
}

.border-width-6px {
  border-width: 6px !important;
}

/*-----transform-position------*/

.position-absolute {
  position: absolute;
}

.position-static {
  position: static;
}

.position-fixed {
  position: fixed;
}

.position-inherit {
  position: inherit;
}

.position-initial {
  position: initial;
}

.position-relative {
  position: relative;
}

.table_image {
  width: 50px !important;
  height: 50px !important;
  display: block;
  object-fit: cover;
  border-radius: 50% !important;
}

.MuiTablePagination-root,
.MuiIconButton-root.Mui-disabled,
.MuiSelect-icon {
  color: #000 !important;
}

.MuiIconButton-label {
  color: #fff !important;
}

.MuiIconButton-root.Mui-disabled .MuiIconButton-label {
  background-color: #f24b91;
}

.MuiIconButton-label {
  color: #28273f;
  background-color: #e5065e;
  border-radius: 5px;
  padding: 5px;
}

.edit-button {
  background-image: linear-gradient(270deg, #48d6a8 0, #029666) !important;
}

.delete-button {
  background-image: linear-gradient(45deg, #d9386a, #f7778c) !important;
}

.bg-primary-gradient {
  background-image: linear-gradient(270deg, #0db2de 0, #005bea) !important;
}

/* -----------custom---------- */
.userName {
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 0;
  color: #009688;
}

.userEmail {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.infoTitle {
  font-size: 14px;
  font-weight: 600;
  color: #888ea8;
  margin-bottom: 11px px;
}

.userPara {
  color: #bfc9d4 !important;
  font-size: 13px !important;
  margin-bottom: 11px !important;
}

.MuiBackdrop-root {
  background-color: #0608188a !important;
}

.MuiPaper-elevation24 {
  box-shadow: none !important;
}

option {
  color: #000 !important;
  background-color: #eef2f6 !important;
}

.sideTgl {
  font-size: 12px !important;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle {
  background-color: #ddddde;
  border-radius: 60px;
  box-shadow: 0 1px 1px 0 rgba(255, 255, 255, 0.4),
    0 1px 0 0 rgba(0, 0, 0, 0.1) inset;
  cursor: pointer;
  width: 52px;
  height: 26px;
  overflow: hidden;
  position: relative;
  /* top: 50px;
  left: 50px; */
  transition: all 0.25s linear;
  margin: auto;
}

.toggle .slide {
  color: #818283;
  color: rgba(0, 0, 0, 0.15);
  background: #efefef;
  border-radius: 50%;
  font-size: 30px;
  line-height: 68px;
  text-align: center;
  text-decoration: none;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 3px;
  left: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15),
    0 1px 1px 0 rgba(255, 255, 255, 0.8) inset;
  transition: all 0.3s cubic-bezier(0.43, 1.3, 0.86, 1);
}

.toggle .slide span {
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.7), 0 0 1px rgba(0, 0, 0, 0.3);
}

.toggle .slide:before,
.toggle .slide:after {
  color: #fff;
  content: "\f007";
  font-family: fontAwesome;
  font-size: 16px;
  font-weight: 400;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.25);
  -webkit-font-smoothing: antialiased;
  position: absolute;
}

.toggle.plan .slide:after {
  content: "\f00c";
  top: -23px;
}

.toggle.plan .slide:before {
  content: "\f05e";
  top: -23px;
}

.toggle .slide:before {
  right: -22px;
  color: #2a2b2c;
  top: -25px;
  opacity: 0.2;
}

.toggle .slide:after {
  content: "\f05e";
  left: -20px;
  top: -24px;
  color: #00ba00;
}

.toggle.on .slide:after {
  color: #fff !important;
}

.toggle.on {
  background: #e70765;
}

.toggle.on .slide {
  left: 29px;
  color: #00d100;
}

.btn {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;


  border-radius: 6px;
  font-size: 14px;

  transition: box-shadow 0.15s ease, transform 0.15s ease;
}

.btn:hover {
  transform: translateY(-2px);
}

.btn.btn-primary {
  background: linear-gradient(177deg,
      rgba(231, 83, 141, 1) 0%,
      #e5065e 35%) !important;
  color: #fff !important;
}

.btn.btn-primary:hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #c31459;
}

.btn.btn-info {
  background: radial-gradient(100% 100% at 100% 0%, #59abdb 0%, #0056db 100%);
  color: #fff !important;
}

.btn.btn-info:hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #0056db;
}

.btn.btn-success {
  background: radial-gradient(100% 100% at 100% 0%,
      #8fcb7c 0%,
      #32701f 100%) !important;
  color: #fff !important;
}

.btn.btn-success:hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #32701f;
}

.btn.btn-danger {
  background: radial-gradient(100% 100% at 100% 0%,
      #df3636 0%,
      #761b1b 100%) !important;
  color: #fff !important;
}

.btn.btn-danger:hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #761b1b;
}

.btn.btn-warning {
  background: radial-gradient(100% 100% at 100% 0%,
      #ede03f 0%,
      #857d1a 100%) !important;
  color: #fff !important;
}

.btn:active {
  /* transform: translateY(2px); */
}

input[type="file"] {
  /* width:90px; */
  color: transparent;
}

.profile-image {
  position: relative;
}

.profile-content {
  position: absolute;
  top: 400px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
}

.infoButton {
  font-size: 26px ;
  transition: all 0.1s ease-in-out;
}

.infoButton:hover {
  font-size: 30px;
}

.MuiTypography-body1 {
  color: #000;
}

.userInfo_button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-transform: lowercase;
  font-family: "Courgette", "cursive";
}